import { Component, createSignal } from 'solid-js'
import { SubmitHandler, createForm, valiForm } from '@modular-forms/solid'
import styles from '~/components/forms/styles/simple-form.module.scss'
import TextField from '~/components/fields/text/text'
import Button from '~/components/button/button'
import { resetPassword } from '~/services/database/users'
import { ResetPasswordSchema, ResetPasswordFormType } from '~/components/forms/account/reset-password-form/reset-password-form.interfaces'
import { reportError } from '~/services/errors'
import { A } from '@solidjs/router'
import { useNavigate } from '@solidjs/router'
import { translations } from '~/translations'

const ResetPasswordForm: Component = () => {
  const navigate = useNavigate()
  const [requestSent, setRequestSent] = createSignal(false)
  const [form, { Form, Field }] = createForm<ResetPasswordFormType>({
    validate: valiForm(ResetPasswordSchema)
  })
  const onSubmit: SubmitHandler<ResetPasswordFormType> = async (values) => {
    const result = await resetPassword({
      password: values.password
    })
    if(result.error){
      const message = `
        ${result.error.name}\n
        ${result.error.message}\n
      `
      reportError(result.error)
      alert(message)
    }else{
      setRequestSent(true)
      setTimeout(() => {
        navigate('/series')
      }, 2000)
    }
  }

  return (
    <div class={styles.container}>
      <div class={styles.title}>
        {translations().forgotPassword.title}
      </div>
      <Form onSubmit={onSubmit} class={styles.form}>
        <Field name="password">
          {(field, props) => (
            <TextField
              label={translations().resetPassword.newPassword}
              type='password'
              autocomplete='new-password'
              {...field}
              {...props}
            />
          )}
        </Field>
        <Button
          type='submit'
          style='lagoon'
          isLoading={form.submitting}
        >
          {translations().general.actions.send}
        </Button>
        {requestSent() && (
          <div class={styles.info}>
            {translations().resetPassword.success}
          </div>
        )}
      </Form>
      <A href='/account/login' class={styles.link}>
        {translations().general.actions.back}
      </A>
    </div>
  )
}

export default ResetPasswordForm

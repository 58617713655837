import ResetPasswordForm from '~/components/forms/account/reset-password-form/reset-password-form'
import Landing from '~/components/landing/landing'

export default function ResetPassword() {
  return (
    <Landing>
      <ResetPasswordForm />
    </Landing>
  )
}
